<template>
    <v-container fluid>  
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                    placeholder="Procurar"
                    dense
                    solo
                    background-color="#FFFFFF"
                    hide-details
                    v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn :disabled="isDisabled" block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                    <v-btn class="mt-3" block color="success" dark @click="$router.back()">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon> Voltar
                    </v-btn>                    
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>COD</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.id"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                    <b>Titulo</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.title"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                    <b>Responsavel</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        :value="getPersonName(objects.idResponsible)"
                        class="my-2"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>Data de Abertura</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.startDate"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                    <b>Data de Fechamento</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.endDate"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <b>Risco</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.risk"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <b>Status</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.status"
                        class="my-2"
                    ></v-text-field>
                </v-col>                
            </v-row>
        </v-card>

        <v-card class="pa-5 mt-5 full-height">
            <v-data-table
                :headers="header"
                :items="activity"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="10"
            >
                <template v-slot:item.idResponsible="{ item }" >
                    {{ getPersonName(item.idResponsible) }}
                </template>
                <template v-slot:item.createdAt="{ item }" >
                    {{ item.createdAt | formatDate3 }}
                </template>
                <template v-slot:item.deadline="{ item }" >
                    {{ item.deadline | formatDate3 }}
                </template>
                <template v-slot:item.endDate="{ item }">
                    <span v-if="item.endDate == '0000-00-00 00:00:00'">Em Aberto</span>
                    <span v-else>{{ item.endDate | formatDate }}</span>
                </template>

                <template v-slot:item.status="{ item }" >
                    <v-chip label small text-color="white" :color="getStatusColor(item.status)">
                        {{ item.status }}
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">                    
                    <TheToolTip label="Vizualizar" v-if="item.status == 'Finalizado'">
                        <v-btn icon small tile >
                            <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Editar" v-if="item.status !== 'Finalizado'">
                        <v-btn :disabled="item.status === 'Finalizado' || objects.status === 'Finalizado'" icon small tile >
                            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover" v-if="item.status !== 'Finalizado'">
                        <v-btn icon small tile >
                            <v-icon :disabled="item.status === 'Finalizado' || objects.status === 'Finalizado'" @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
                
            </v-data-table>
        </v-card>

        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="800px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col md="6" sm="12" cols="12">
                            <b>Titulo da ação *</b>
                            <v-text-field
                                :rules="[requiredRule, spaceRule]"
                                v-model="object.title"
                                outlined
                                tile
                                dense
                                class="my-2"
                            ></v-text-field>
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                            <b>Responsável<sup>*</sup></b>
                            <v-select
                                placeholder="Selecione"
                                v-model="object.idResponsible"
                                :items="people"
                                item-text="name"
                                item-value="id"
                                class="my-2"
                                dense
                                hide-details
                                outlined
                                color="primary"
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="6" sm="12" cols="12">
                            <b> Prazo *</b>
                            <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="object.deadline"
                                        placeholder="Data"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @input="date = parseDate(object.deadline)"
                                        v-on="on"
                                        hide-details
                                        dense
                                        outlined
                                        class="mt-2"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :rules="requiredRule"
                                    v-model="date"
                                    no-title
                                    @input="menu1 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                            <b>Status</b>
                            <v-select
                                v-model="object.status"
                                :items="status"
                                placeholder="Selecione"
                                outlined
                                tile
                                dense
                                class="mt-2"
                                color="primary"
                            ></v-select>                    
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <b> Descrição da Ação *</b>
                            <v-textarea
                                outlined
                                rows="3"
                                v-model="object.description"
                                class="my-2"
                                auto-grow
                                :rules="[requiredRule, spaceRule]"
                            ></v-textarea>
                        </v-col>
                    </v-row>                    
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit" ><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>

        <!-- Vizualização -->
        <template>
            <v-dialog v-model="dialogView" max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Detalhes da Atividade</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col md="2" sm="12" cols="12">
                                <b>COD</b>
                                <v-text-field
                                    v-model="objectView.id"
                                    hide-details
                                    dense
                                    outlined
                                    class="mt-2"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col md="5" sm="12" cols="12">
                                <b>Nome da Atividade</b>
                                <v-text-field
                                    v-model="objectView.title"
                                    hide-details
                                    dense
                                    outlined
                                    class="mt-2"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col md="5" sm="12" cols="12">
                                <b>Responsável</b>
                                <v-text-field
                                    placeholder="Selecione"
                                    v-model="objectView.name"
                                    class="my-2"
                                    dense
                                    hide-details
                                    outlined
                                    color="primary"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row>
                            <v-col md="6" sm="12" cols="12">
                                <b> Prazo </b>
                                <v-text-field
                                    v-model="objectView.deadline"
                                    hide-details
                                    dense
                                    outlined
                                    class="mt-2"
                                    disabled
                                ></v-text-field>      
                            </v-col>                           
                            <v-col md="6" sm="12" cols="12">
                                <b>Status</b>
                                <v-text-field
                                    v-model="objectView.status"
                                    :items="status"
                                    outlined
                                    tile
                                    dense
                                    class="mt-2"
                                    color="primary"
                                    disabled
                                ></v-text-field>                    
                            </v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <b> Descrição da Ação </b>
                                <v-textarea
                                    outlined
                                    rows="3"
                                    v-model="objectView.description"
                                    class="my-2"
                                    auto-grow
                                    disabled
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="closeDialog()">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

    </v-container>
</template>

<script>
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import { mask } from "vue-the-mask"
    import { baseApiUrl, showError } from "@/global"
    import axios from "axios"
    import moment from 'moment'
    import { formatDate3 } from "@/plugins/filters";
  
    export default {
    
        name: "PlanoAcaoAtividade",
        directives: { mask },
        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
        },
        
        data: () => ({
            
            baseRoute: "security-plan",
            title: "Atividade do Plano de Ação",
            pageIcon: "mdi mdi-run-fast",

            objects: [],
            object: {},
            people:[],
            activity:[],
            objectView: [],

            menu1: false,
            date: null,
            dialogView: false,

            dialog: false,
            search: null,
            valid: false,
            error: false,
            edit: false,
            loading: false,
            isDisabled: false,
            filters: { formatDate3 },  
            
            header: [
                {text: 'COD', value: 'id'},
                {text: 'Titulo da Atividade', value: 'title'},
                {text: 'Nome', value: 'idResponsible'},
                {text: 'Data de Inicio', value: 'createdAt'},
                {text: 'Prazo', value: 'deadline'},
                {text: 'Finalizado', value: 'endDate'},
                {text: 'Status', value: 'status'},
                {text: 'Ações', value: 'actions'},
            ],

            status: [
                {text: 'Pendente', value: 'Pendente'},
                {text: 'Em andamento', value: 'Em andamento'},
                {text: 'Finalizado', value: 'Finalizado'},
            ],

        }),

        watch: {
            date() {
                this.object.deadline = this.date
                this.object.deadline = moment(this.object.deadline).format('DD/MM/YYYY')
            },
        },

        computed: {

            getPersonName() {
                return function (idResponsible) {
                    const person = this.people.find((el) => el.id === idResponsible);
                    if (person) {
                        return person.name;
                    } else {
                        return "";
                    }
                };
            },
        },

        methods: {  

            loadPerson() {
                const url = `${baseApiUrl}/people`
                return axios
                    .get(url)
                    .then((res) => {
                        this.people = res.data
                    })
                    .catch(showError)
            },

            async loadData() {
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/search/${this.$route.params.id}/security-plan-activity`

                const res = await axios.get(url)
                this.$store.dispatch("setLoadingInfo", false)
                this.activity = res.data
            },

            async loadItem() {
                const id = this.$route.params.id;

                this.$store.dispatch("setLoadingInfo", true);
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`;

                try {
                    const res = await axios.get(url);
                    this.$store.dispatch("setLoadingInfo", false);
                    this.objects = res.data;

                    this.objects.startDate = moment(this.objects.startDate).format('DD/MM/YYYY')
                    this.objects.endDate = moment(this.objects.endDate).format('DD/MM/YYYY')

                    if(this.objects.status == 'Em andamento' || this.objects.status == 'Pendente'){
                        delete this.objects.endDate
                        this.objects.endDate = "Em Aberto"
                    }

                    if(this.objects.status == "Finalizado"){
                        this.isDisabled = true;
                    }
                } catch (error) {
                    showError(error);
                }
            },           

            dialogSave() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;                
                this.object.deadline = moment(new Date(this.object.deadline)).format('DD/MM/YYYY')
                this.edit = true
                this.dialog = true         
            },  
            
            dialogView1(item) {
                this.objectView = item;
                this.objectView.deadline = moment(new Date(this.objectView.deadline)).format('DD/MM/YYYY');
                this.dialogView = true;
            },  
            
            closeDialog() {
                this.objectView = {}
                this.dialogView = false
                this.loadData()  
            },

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },
            
            save(){
                this.error = false
                const url = `${baseApiUrl}/security-plan-activity`
                const objects = this.object  
                objects.idPlanSecurity = this.objects.id 

                const date = this.object.deadline.split('/')
                const parseDate = `${date[2]}-${date[1]}-${date[0]}` 
                objects.deadline = parseDate

                if(objects.status == 'Finalizado'){
                    objects.endDate = new Date()
                }
                                
                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })    
                    
                this.loadData()
            },

            update() {
                this.error = false
                const id = this.object.id
                const url = `${baseApiUrl}/security-plan-activity/${id}`
                const objects = this.object            

                const data = this.object.deadline.split('/')
                const parseDate = `${data[2]}-${data[1]}-${data[0]}` 

                objects.deadline = parseDate

                if(objects.status == 'Finalizado'){
                    objects.endDate = new Date()
                } else {
                    delete objects.endDate
                }               

                delete objects.name
                delete objects.id

                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt
                
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })
            },
                            
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/security-plan-activity/${id}` 
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },

            getStatusColor(status) {
                if (status == "Em andamento") {
                    return "#FBC02D"
                }
                else if (status == "Finalizado") {
                    return "#4CAF50"
                }
                else if (status == "Pendente") {
                    return "#757575" 
                }
            },

            parseDate(date) {
                if (!date) return null
                const [month, day, year] = date.substring(0, 11).split("/")
                return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
            },
        },
        mounted() {
            this.loadPerson()
            this.loadData()
            this.loadItem()
        }
    }
</script>

<style>
.full-height {
  height: 40vh;
}
</style>